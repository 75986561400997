import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import H1Category from "../../../components/h1Category"
import ContentPage from "../../../layouts/contentPage"
import ProductBox from "../../../components/productBox"
import ProductBoxCta from "../../../components/productBox/productBoxCta"
import ProducstList from "../../../components/productsList"
import Seo from "../../../components/seo"

import ArrowRight from "../../../images/arrow-right.svg"
import ProductBanner from "../../../components/productBanner"




const Category = () => (
    <Layout lang={"EN"}>
      <Seo title="Relift" lang={"EN"}/>
      <ContentPage>
            <H1Category color="magenta">Relift</H1Category>
            <ProducstList>

                <ProductBox data-color="magenta">
                    <Link to="/en/products/lifting-serum">
                        <StaticImage
                        src="../../images/media/products/relift/category/liftingujace-serum.png"
                        loading="eager"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Liftingujące serum"
                        />
                        <ProductBoxCta className="cta" data-color="magenta">
                            <div>
                                <h3>Neck and décolleté </h3>
                                <p>lifting serum</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="magenta">
                    <Link to="/en/products/anti-aging-serum">
                        <StaticImage
                        src="../../images/media/products/relift/category/odmladzajace-serum.png"
                        loading="eager"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Odmładzające serum"
                        />
                        <ProductBoxCta className="cta" data-color="magenta">
                            <div>
                                <h3>Anti-aging </h3>
                                <p>face day serum SPF 15</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="magenta">
                    <Link to="/en/products/ultra-repair-serum">
                        <StaticImage
                        src="../../images/media/products/relift/category/ultranaprawcze-serum.png"
                        loading="eager"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Ultranaprawcze serum"
                        />
                        <ProductBoxCta className="cta" data-color="magenta">
                            <div>
                                <h3>Ultra repair </h3>
                                <p>night-time face serum</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="magenta">
                    <Link to="/en/products/smoothing-serum">
                        <StaticImage
                        src="../../images/media/products/relift/category/wygladzajace-serum.png"
                        loading="lazy"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Wygładzające serum"
                        />
                        <ProductBoxCta className="cta" data-color="magenta">
                            <div>
                                <h3>Smoothing </h3>
                                <p>eye serum</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBanner wide productLine="relift" />


          </ProducstList>
      </ContentPage>
    </Layout>
)

export default Category
